import * as React from "react";

const footerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "left",
  fontSize: "16px",
};

const footerTitleStyles = {
  fontFamily: "Commissioner",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#878787",
};

const contactStyles = {
  color: "#FFFFFF",
  lineHeight: "25px",
};

const Footer = () => {
  return (
    <div id="footer" style={footerStyles}>
      <p style={footerTitleStyles}>Don't miss out.</p>
      <div style={contactStyles}>
        <p style={{ margin: 0 }}>(USA) +1 661 458 8468</p>
        <p style={{ margin: 0 }}>sayhi@devzero.uy</p>
      </div>
    </div>
  );
};

export default Footer;
