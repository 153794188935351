import * as React from "react";

import "./content.scss";
import astronaut from "../images/astronaut.svg";

import { PopupButton } from "@typeform/embed-react";

const containerStyles = {
  display: "flex",
};

const contentStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "left",
  marginBottom: "111px",
};

const headerStyles = {
  fontWeight: 700,
  fontSize: "64px",
  lineHeight: "96px",
  maxWidth: "680px",
  margin: 0,
};

const descriptionStyles = {
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "32px",
  margin: "24px 0 32px",
  maxWidth: "540px",
};

const ctaStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px 24px",
  background: "#FFFFFF",
  borderRadius: "5px",
  width: "133px",
  fontFamily: "Commissioner, serif",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#141414",
  border: "none",
  cursor: "pointer",
};

const animationStyles = {
  position: "absolute",
  top: "50px",
  right: "60px",
  height: "700px",
  width: "auto",
  animationName: "astronaut",
  animationDuration: "4s",
  animationIterationCount: "infinite",
  animationTimingFunction: "linear",
  animationDirection: "alternate",
};

const Content = () => {
  return (
    <div id="content" style={containerStyles}>
      <div style={contentStyles}>
        <h1 style={headerStyles}>Top 1% of Uruguay's engineers.</h1>
        <p style={descriptionStyles}>
          Get access to the best engineers in the Silicon Valley of Latin
          America and accelerate your growth to the moon.
        </p>
        <PopupButton id="pRleseY9" style={ctaStyles}>
          Get Started
        </PopupButton>
      </div>
      <div className="astronaut">
        <img style={animationStyles} src={astronaut} alt="" />
      </div>
    </div>
  );
};

export default Content;
