import * as React from "react";
import Content from "../components/content";
import Footer from "../components/footer";
import TopBar from "../components/topbar";
import { Helmet } from "react-helmet";

// styles
import "../styles/typography.scss";
import "./index.scss";

const pageStyles = {
  color: "#FFFFFF",
  backgroundColor: "#141414",
  fontFamily: "Space Grotesk, serif",
  height: "100%",
  minHeight: "100vh",
};

// markup
const IndexPage = () => {
  return (
    <main className="mainPage" style={pageStyles}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          devzero | hire remote developers from the silicon valley of latin
          america
        </title>
        <link rel="canonical" href="https://www.devzero.uy" />
      </Helmet>
      <TopBar />
      <Content />
      <Footer />
      <script src="//embed.typeform.com/next/embed.js"></script>
    </main>
  );
};

export default IndexPage;
