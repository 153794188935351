import * as React from "react";

const topBarStyles = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "80px",
  alignItems: "center",
};

const logoStyles = {
  fontFamily: "Commissioner, serif",
  fontWeight: 700,
  fontSize: "32px",
  margin: "22px 0",
  lineHeight: "40px",
};

const logoAlternateStyles = {
  color: "#878787",
};

const navLinksStyles = {
  display: "flex",
  fontFamily: "Commissioner, serif",
  color: "#878787",
  zIndex: "1",
};

const navLinkActive = {
  color: "#FFFFFF",
};

const TopBar = () => {
  return (
    <div id="topbar" style={topBarStyles}>
      <h1 style={logoStyles}>
        dev<span style={logoAlternateStyles}>zero</span>
      </h1>
      <div style={navLinksStyles}>
        <p className="topbar__home" style={navLinkActive}>
          Home
        </p>
        <p style={{ marginLeft: "40px" }}>
          <a
            href="https://www.american.edu/centers/latin-american-latino-studies/upload/uruguay-tech-sector_case-vignette.pdf"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Why Uruguay?
          </a>
        </p>
      </div>
    </div>
  );
};

export default TopBar;
